import React from 'react'
import "./About.css"

const About = () => {
    return (
        <section id="about">
            <div className='about-container'>
                <div className='about-heading' data-aos="flip-left">
                    <strong>About Me</strong>
                    <h3>Designing with passion for Problem solving</h3>
                    <a href="tel:0769838892" className='btn-link'>
                        +949838892 <i className="fa-solid fa-phone-flip"></i>
                    </a>
                </div>
                <div className='about-details' data-aos="flip-right">
                    <p>I am a passionate and innovative software engineer with a deep love for coding and problem-solving. With a solid foundation in computer science and extensive experience in software development, I thrive on turning complex ideas into efficient and elegant solutions. From designing scalable systems to writing clean and maintainable code, I take pride in my ability to contribute to the entire software development life cycle. I am adept at collaborating with cross-functional teams, communicating technical concepts to non-technical stakeholders, and delivering high-quality software solutions that meet or exceed customer expectations. Constantly seeking to expand my knowledge, I stay up-to-date with the latest industry trends and best practices. Whether it's front-end development, back-end systems, or full-stack solutions, I am committed to creating software that not only meets the functional requirements but also provides an exceptional user experience.</p>

                </div>
            </div>
        </section>
    )
}

export default About