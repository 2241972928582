import React from 'react'
import About from './components/About/About'
import Career from './components/Career/Career'
import Contact from './components/Contact/Contact'
import Footer from './components/Footer/Footer'
import Home from './components/Home/Home'
import Progress from './components/Progress/Progress'
import Projects from './components/Projects/Projects'
import Summary from './components/Summary/Summary'
import Technologies from './components/Technologies/Technologies'
import Theme from './components/Theme/Theme'

const App = () => {
  return (
    <>
      <Theme />
      <Progress />
      <div className='container'>
        <Home />
        <Summary />
        <Technologies />
        <Projects />
        <Career />
        <About />
        <Contact />
        <Footer />
      </div>
    </>
  )
}

export default App