import React from 'react'
import "./Footer.css"

const Footer = () => {
    return (
        <footer>
            <span>Copyright &copy;2023</span>

            <div className='social-links'>
                <a href="https://www.facebook.com/barath.gowribalakurukkal">
                    <i className='fa-brands fa-facebook'></i>
                </a>

                <a href="www.linkedin.com/in/sribarathvajasarma-gowribalakurukkal-58bb561a4">
                    <i className='fa-brands fa-linkedin'></i>
                </a>

                <a href="https://github.com/Sribarathvajasarma">
                    <i className='fa-brands fa-github'></i>
                </a>
            </div>

            <a href="#!" className='footer-logo'>Barath</a>
        </footer>
    )
}

export default Footer