import React from 'react'
import "./Contact.css"

const Contact = () => {
    return (
        <section id="contact" data-aos="fade-down">
            <strong>What's Next?</strong>
            <h3>Let's Work Together</h3>
            <p>I can add a value of the growth of your company or project with my knowledge and skillset</p>
            <a href="mailto:gsribarathvajasarma@gmail.com" className='btn-link contact-btn'>
                Write Me An Email <i className='fa-solid fa-envelope'></i>
            </a>
        </section>
    )
}

export default Contact