import React from 'react'
import './Technologies.css'

const Technologies = () => {
    const data = [
        {
            title: "Languages",
            contents: ["Python", "Javascript", "Java", "NodeJs", "SQL"]
        },
        {
            title: "Databases",
            contents: ["MySQL", "PostgreSQL", "MongoDB", "Firebase"]
        },
        {
            title: "Others",
            contents: ["Docker", "Kubernetes", "Machine Learning", "Bash script"]
        },

    ]
    return (
        <section id="languages" data-aos="fade">
            {
                data.map(item => (
                    <div className="languages-box" key={item.title}>
                        <h1>{item.title}</h1>
                        <ul>
                            {
                                item.contents.map(content => (
                                    <li key={content}>{content}</li>
                                ))
                            }
                        </ul>
                    </div>
                ))
            }

        </section>
    )
}

export default Technologies