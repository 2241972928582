import React from 'react'
import './Career.css'
import CV from '../../assets/CV/CV.pdf';

const Career = () => {
    const careers = [
        {
            title: "Software Engineer Intern",
            company: "WSO2",
            year: "2022 - 2023"

        }
    ]

    const education = [
        {
            title: "BSc (Hons) Software Engineering",
            company: "UCSC",
            year: "2019 - Present"

        },
        {
            title: "Advance Level (Physical Science)",
            company: "J/Hartley College",
            year: "2016 - 2018"

        },
        {
            title: "Ordinary Level",
            company: "J/Hartley College",
            year: "2015"

        }

    ]
    return (
        <section id="career">
            <div className='career-education'>
                <div className='career' data-aos="fade-right">
                    <h3>Career</h3>
                    <div className='c-b-container'>
                        {
                            careers.map((career, index) => (
                                <div className='c-box' key={index}>
                                    <h4>{career.title}</h4>
                                    <strong>{career.company}</strong>
                                    <span>{career.year}</span>
                                </div>
                            ))
                        }
                    </div>

                </div>

                <div className='career edu' data-aos="fade-left">
                    <h3>Education</h3>
                    <div className='c-b-container'>
                        {
                            education.map((career, index) => (
                                <div className='c-box' key={index}>
                                    <h4>{career.title}</h4>
                                    <strong>{career.company}</strong>
                                    <span>{career.year}</span>
                                </div>
                            ))
                        }
                    </div>

                </div>

            </div>
            <div className='btn-c'>
                <a href={CV} download className='btn-link'>
                    Get CV <i class="fa-solid fa-download"></i>
                </a>
            </div>
        </section>
    )
}

export default Career