import React from 'react'
import './Summary.css'
import CV from '../../assets/CV/CV.pdf'

const Summary = () => {
    return (
        <section id="summary">
            <div className='summary-heading' data-aos="fade-right">
                <strong>My Skillset</strong>
                <h2>Leadership, Innovation, Critical thinking, Time management</h2>
                <a href={CV} download className='btn-link'>
                    Get CV <i className="fa-solid fa-download"></i>
                </a>
            </div>

            <div className='summary-details' data-aos="fade-left">
                <p>
                    I am currently studying BSc(Hons) in Software Engineering at University of Colombo School of Computing. I have good academic background with 3.82 GPA. I did many projects with innovative ideas including AgrOM, Giverzen, Bloodzen, ReadEra. I worked as software engineer intern at WSO2 and completed 2 research projects related to Choreo. My reserch interests are related to Software developement and Machine Learning.

                </p>

            </div>

        </section>
    )
}

export default Summary